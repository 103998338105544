import { noop } from './noop';
export function pipe(...fns) {
  return pipeFromArray(fns);
}
export function pipeFromArray(fns) {
  if (!fns) {
    return noop;
  }
  if (fns.length === 1) {
    return fns[0];
  }
  return function piped(input) {
    return fns.reduce((prev, fn) => fn(prev), input);
  };
}
